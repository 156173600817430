// Old - not being used?
<template>
    <div v-if="loading == false">
        {{ report_name }}
        <label>Speed Date</label>
        <select :v-model="speed_date_val" @change="speed_date">
            <option v-for="v in dates_dropdown" :key="v" :value="v">{{ v.replaceAll('_', ' ') }}</option>
        </select>
        <div>
            <Input
                type="date"
                placeholder=""
                v-model="record_data.start_date"
                label="Start Date"
                :disabled="!isEditable"
            />
        </div>
        <div>
            <Input
                type="date"
                placeholder=""
                v-model="record_data.end_date"
                label="Start Date"
                :disabled="!isEditable"
            />
        </div>
        <ChirpReport
            :data_source="rows"
            :target_page="targetPage"
            @applyCriteria="modifyCriteria"
            :loadingColumns="loadingTable"
            :criteriaProps="criteriaProps"
        />
    </div>
</template>

<script>
    const api_root = 'reports';
    const report = 'unpaid_claims';

    import ChirpReport from '@/components/general/list/ChirpReport';

    export default {
        name: 'Reports',
        components: { ChirpReport },
        props: {
            report_name: {
                type: String,
                Default: 'unpaid_claims',
            },
        },
        data() {
            return {
                rows: [],
                isEditable: 1,
                targetPage: 'InsuranceClaimDetail',
                loading: true,
                loadingTable: false,
                criteriaProps: {},
                dates_dropdown: [],
                speed_date_val: 'current_month',
                record_data: {
                    start_date: '',
                    end_date: '',
                },
            };
        },
        methods: {
            async load_data() {
                this.criteriaProps.date_range = this.record_data;
                this.criteriaProps.report = report;
                if (this.report_name) {
                    this.criteriaProps.report;
                }
                const body = {
                    criteria: this.criteriaProps,
                };
                const res = await this.$api.post(`/${api_root}`, body);
                this.rows = res.data.rows;
                this.criteriaProps = {
                    filters: this.pageMeta.filters,
                    total_count: res.data.total_count,
                };
                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {

                for (const [k] of Object.entries(this.date_ranger)) {
                    this.dates_dropdown.push(k); 
                }

                this.record_data.start_date = this.date_ranger.current_month.start;

                this.record_data.end_date = this.date_ranger.current_month.end;

                this.loading = false;
                // if (this.$route.params && this.$route.params.criteria_props) {
                //     this.criteriaProps = this.$route.params.criteria_props
                // }
                // await this.load_data()
            },
            // async modifyCriteria(criteria) {
            // if (this.targetPage) {
            //     this.$store.commit('filters/update', {
            //         criteriaPage: this.targetPage,
            //         payload: criteria
            //     })
            // }
            // this.criteriaProps = criteria
            // this.load_data()
            // },
            async speed_date(e) {
                const v = e.target.value;
                if (v && this.date_ranger[v]) {
                    this.record_data.start_date = this.date_ranger[v].start;
                    this.record_data.end_date = this.date_ranger[v].end;
                }
            },
        },
        async created() {
            await this.init();
        },
    };
</script>
